
import { defineComponent, PropType, toRefs } from 'vue';
import {
  IonItem,
  IonLabel,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
} from '@ionic/vue';
import DeliveryStatus from '@/components/shared/DeliveryStatus.vue';
import { modal } from '@/composables/ionicControllers';
import { Job } from '@/types/merchant';

import { reorderTwoOutline, reorderTwoSharp } from 'ionicons/icons';

export default defineComponent({
  props: {
    delivery: Object as PropType<Job>,
    updateOrderStatus: Function,
    isStatusLoading: Boolean,
  },
  components: {
    IonItem,
    IonLabel,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
  },
  setup(props) {
    const { delivery, updateOrderStatus } = toRefs(props);
    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    };
    const openModal = async () => {
      const md = await modal(
        DeliveryStatus,
        {
          delivery: delivery.value,
          updateOrderStatus: updateOrderStatus.value,
        },
        'task-detail-modal',
      );
      return await md.present();
    };

    return {
      formatDate,
      reorderTwoOutline,
      reorderTwoSharp,
      openModal,
    };
  },
});
