
import { defineComponent, toRefs } from 'vue';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonList,
  IonSpinner,
  IonButton,
  IonImg,
} from '@ionic/vue';
import { merchants } from '@/store';

import TaskListItem from './TaskListItem.vue';
import { openToast, loading } from '@/composables/ionicControllers';
import { onShare } from '@/composables/utils';
import { getConfig } from '@/lib/constants';

export default defineComponent({
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonList,
    TaskListItem,
    IonSpinner,
    IonButton,
    IonImg,
  },
  props: {
    recentJobs: { type: Array },
    updateRecentJobs: { type: Function },
    updateStats: { type: Function },
    loading: { type: Boolean },
  },
  setup(props) {
    const { recentJobs, updateRecentJobs, updateStats } = toRefs(
      props,
    );
    const updateOrderStatus = async (
      id: number,
      status: string,
    ): Promise<void> => {
      if (status === 'completed') {
        if (
          window.confirm(
            'Are you sure you want to mark this task as completed?, this action cannot be reverted',
          )
        ) {
          const loader = await loading();
          loader.present();
          const response = await merchants.updateJobStatus(
            id,
            status.toLowerCase(),
          );
          if (response.ok) {
            loader.dismiss();
            const jobs: any = recentJobs.value;
            if (jobs) {
              for (const j in jobs) {
                if (jobs[j].id === id) {
                  jobs[j].status = status;
                  break;
                }
              }
            }
            // update recent jobs
            if (updateRecentJobs.value) updateRecentJobs?.value(jobs);
            // update stats
            const stats = jobs.reduce(
              (acc: any, job: any) => {
                acc[job.status] += 1;
                return acc;
              },
              {
                ongoing: 0,
                pending: 0,
                cancelled: 0,
                completed: 0,
              },
            );
            if (updateStats.value) updateStats.value(stats);
          } else {
            loader.dismiss();
            await openToast(
              'Unable to update job status, please try again later.',
            );
          }
          return;
        }
        return;
      }
      const loader = await loading();
      loader.present();
      const response = await merchants.updateJobStatus(
        id,
        status.toLowerCase(),
      );
      if (response.ok) {
        loader.dismiss();
        const jobs: any = recentJobs.value;
        if (jobs) {
          for (const j in jobs) {
            if (jobs[j].id === id) {
              jobs[j].status = status;
              break;
            }
          }
        }
        // update recent jobs
        if (updateRecentJobs.value) updateRecentJobs?.value(jobs);
        // update stats
        const stats = jobs.reduce(
          (acc: any, job: any) => {
            acc[job.status] += 1;
            return acc;
          },
          {
            ongoing: 0,
            pending: 0,
            cancelled: 0,
            completed: 0,
          },
        );
        if (updateStats.value) updateStats.value(stats);
      } else {
        loader.dismiss();
        await openToast(
          'Unable to update job status, please try again later.',
        );
      }
      return;
    };

    const share = () => {
      onShare({
        title: 'Book DLVRy Link',
        url: `${getConfig().APP_URL}/${
          merchants.state.merchant?.slug
        }/book`,
      });
    };

    return {
      updateOrderStatus,
      share,
    };
  },
});
