
import { defineComponent } from "vue";
import { IonRow, IonCol, IonGrid } from "@ionic/vue";
import {
  timeOutline,
  timeSharp,
  checkboxOutline,
  checkboxSharp,
  closeCircleOutline,
  closeCircleSharp,
  trendingUpOutline,
  trendingUpSharp,
} from "ionicons/icons";

import DashboardStatistic from "./DashboardStatistic.vue";

export default defineComponent({
  components: {
    IonRow,
    IonCol,
    IonGrid,
    DashboardStatistic,
  },
  props: {
    stats: { type: Object },
    loading: { type: Boolean },
  },
  setup() {
    // const st = toRefs(props)
    return {
      checkboxOutline,
      checkboxSharp,
      timeOutline,
      timeSharp,
      closeCircleOutline,
      closeCircleSharp,
      trendingUpOutline,
      trendingUpSharp,
    };
  },
});
