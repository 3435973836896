import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78f9326a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-no-margin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { color: _ctx.color }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { class: "ion-no-padding" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { size: "auto" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        md: _ctx.iconMd,
                        ios: _ctx.iconIos
                      }, null, 8, ["md", "ios"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.value), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["color"]))
}