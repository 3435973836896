
import { defineComponent } from "vue";
import {
  IonCard,
  IonGrid,
  IonCol,
  IonRow,
  IonCardContent,
  IonIcon,
  IonText,
} from "@ionic/vue";

export default defineComponent({
  components: {
    IonCard,
    IonGrid,
    IonCol,
    IonRow,
    IonCardContent,
    IonIcon,
    IonText,
  },
  props: {
    iconMd: String,
    iconIos: String,
    value: String,
    description: String,
    color: String,
  },
});
