
import { defineComponent, ref, onMounted } from "vue";
import {
  IonContent,
  IonPage,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonMenuButton,
  IonIcon,
  IonButton,
} from "@ionic/vue";
import {
  timeOutline,
  timeSharp,
  checkboxOutline,
  checkboxSharp,
  closeCircleOutline,
  closeCircleSharp,
  trendingUpOutline,
  trendingUpSharp,
  shareSocialOutline,
} from "ionicons/icons";
import { merchants } from "@/store";
import { Job, JobStats } from "@/types/merchant";
import { openToast } from "@/composables/ionicControllers";
import DashboardStatistics from "./DashboardStatistics.vue";
import TasksList from "./TasksList.vue";
import { getConfig } from "@/lib/constants";
import { onShare } from "@/composables/utils";

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonMenuButton,
    DashboardStatistics,
    TasksList,
    IonIcon,
    IonButton,
  },
  setup() {
    const recentJobs = ref<Job[] | null>();
    const stats = ref<JobStats | null>();
    const loadingRecentJobs = ref<boolean>(false);
    const { loadAllJobs } = merchants;
    const updateRecentJobs = (jobs: Job[]) =>
      (recentJobs.value = jobs);
    const updateStats = (stat: {
      ongoing: number;
      pending: number;
      completed: number;
      cancelled: number;
    }) => (stats.value = stat);
    onMounted(async () => {
      loadingRecentJobs.value = true;
      const result = await loadAllJobs();
      if (result.ok) {
        loadingRecentJobs.value = false;
        const {
          jobs,
          ongoing,
          pending,
          completed,
          cancelled,
        } = result.data;
        recentJobs.value = jobs.sort(
          (a: any, b: any) =>
            new Date(b.timeCreated).getTime() -
            new Date(a.timeCreated).getTime(),
        );
        stats.value = {
          ongoing,
          pending,
          completed,
          cancelled,
        };
      } else {
        loadingRecentJobs.value = false;
        await openToast(result.message as string);
      }
    });

    const share = () => {
      onShare({
        title: "Book DLVRy Link",
        url: `${getConfig().APP_URL}/${
          merchants.state.merchant?.slug
        }/book`,
      });
    };

    return {
      pageTitle: "Dashboard",
      checkboxOutline,
      checkboxSharp,
      timeOutline,
      timeSharp,
      closeCircleOutline,
      closeCircleSharp,
      trendingUpOutline,
      trendingUpSharp,
      recentJobs,
      shareSocialOutline,
      updateRecentJobs,
      updateStats,
      share,
      loadingRecentJobs,
      stats,
    };
  },
});
