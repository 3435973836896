import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dashboard_statistic = _resolveComponent("dashboard-statistic")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "statistics-group" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_dashboard_statistic, {
                iconMd: _ctx.checkboxOutline,
                iconIos: _ctx.checkboxSharp,
                value: !_ctx.loading && _ctx.stats ? `${_ctx.stats.completed}` : '--',
                description: "Completed",
                color: "success"
              }, null, 8, ["iconMd", "iconIos", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_dashboard_statistic, {
                iconMd: _ctx.timeOutline,
                iconIos: _ctx.timeSharp,
                value: !_ctx.loading && _ctx.stats ? `${_ctx.stats.pending}` : '--',
                description: "Pending",
                color: "warning"
              }, null, 8, ["iconMd", "iconIos", "value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_dashboard_statistic, {
                iconMd: _ctx.closeCircleOutline,
                iconIos: _ctx.closeCircleSharp,
                value: !_ctx.loading && _ctx.stats ? `${_ctx.stats.cancelled}` : '--',
                description: "Cancelled",
                color: "danger"
              }, null, 8, ["iconMd", "iconIos", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_dashboard_statistic, {
                iconMd: _ctx.trendingUpOutline,
                iconIos: _ctx.trendingUpSharp,
                value: !_ctx.loading && _ctx.stats ? `${_ctx.stats.ongoing}` : '--',
                description: "Ongoing",
                color: "primary"
              }, null, 8, ["iconMd", "iconIos", "value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}